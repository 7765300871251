// Theme colors
$primary: #f6911e;
$secondary: #76bd23;

// Theme variables
$btn-border-width: 2px;

@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/_mixins.scss";
@import "node_modules/bootstrap/scss/_utilities.scss";
@import 'node_modules/bootstrap/scss/_buttons.scss';
@import 'node_modules/bootstrap/scss/_dropdown.scss';
@import 'node_modules/bootstrap/scss/_pagination.scss';
@import 'node_modules/bootstrap/scss/_modal.scss';
@import 'node_modules/bootstrap/scss/_toasts.scss';
@import './bootstrap_tooltip.scss';
@import './boostrap_popover.scss';

.ngb-tooltip-no-backround {
    &.show {
        opacity: 1;
    }
    will-change: unset !important;
    .tooltip-inner {
        padding: 0;
        background: none;
    }
}

.btn-primary {
    border-radius: 10px;
    text-transform: none;

    &:not(:disabled):not(.disabled) {
        color: #fff;
        background-color: $primary;

        &:hover, &:focus, &:active {
            border-color: black;
            background-color: $primary;
        }

        &:focus, &:active {
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
        }

        &:active:focus {
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
        }
    }

    &:disabled, &.disabled {
        color: black;
        background-color: #ccc;
        border-color: #ccc;
        cursor: default;
    }
}

.btn-secondary {
    border-radius: 10px;
    text-transform: none;

    &:not(:disabled):not(.disabled) {
        color: #fff;
        background-color: $secondary;

        &:hover, &:focus, &:active {
            border-color: black;
            background-color: $secondary;
        }

        &:focus, &:active {
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
        }

        &:active:focus {
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
        }
    }

    &:disabled, &.disabled {
        color: black;
        background-color: #ccc;
        border-color: #ccc;
        cursor: default;
    }
}

.btn-outline-primary {
    border-radius: 10px;
    text-transform: none;

    &:not(:disabled):not(.disabled) {
        color: black;
        background-color: white;
        border-color: #aaa;

        &.btn-outline-transparent-border {
            border-color: transparent;
        }

        &:hover, &:focus, &:active {
            border-color: $primary;
            background-color: white;
        }

        &:focus, &:active {
            box-shadow: 0 0 0 1px rgba(246, 146, 30, 0.5);
        }

        &:active:focus {
            box-shadow: 0 0 0 3px rgba(246, 146, 30, 0.5);
        }
    }

    &:disabled, &.disabled {
        color: #444;
        background-color: #eee;
        border-color: #ddd;
        cursor: default;
    }
}

.modal-content {
    .modal-header {
        > .modal-title {
            margin: 0;
            color: #212529;
            font-family: "Roboto Medium", sans-serif;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 40px;
        }
        > button.close {
            margin: 0 0 0 auto;
            padding: 0.375rem 0.75rem;
        }
    }
}

.squared-dialog-style {
    .modal-content {
        border-radius: 0;
        .modal-header {
            padding: 1.5rem;
            > .modal-title {
                font-family: 'Roboto';
                font-size: 32px;
                letter-spacing: 0;
                line-height: 38px;
                color: #666;
                font-weight: normal;
            }
            > button {
                border-radius: 0;
                margin-top: -1.5rem;
                margin-right: -1.5rem;
            }
        }
    }
}

.toast-header {
    button {
        border-width: 0;
        background-color: transparent;
        -webkit-appearance: none;
    }
}